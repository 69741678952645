/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import GlobalStyle from '../shared/baseCSS'

import { useStaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { Theme } from '../shared/theme'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

import styled from 'styled-components'
import Transition from '../components/transition'
import Menu from '../components/menu'

const BodyWrapper = styled.div``

const Layout = ({ children, location }) => {
	typeof window !== 'undefined'

	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	const locationUndefined = {
		pathname: '/',
	}
	const currentLocation = location || locationUndefined

	let slug = currentLocation.pathname == '/' ? 'home' : currentLocation.pathname

	return (
		<BodyWrapper id="body-wrapper">
			<ThemeProvider theme={Theme}>
				<GlobalStyle />
				<Header
					siteTitle={data.site.siteMetadata?.title || `Title`}
					routeSlug={slug}
					currentLocation={location}
				/>
				<Transition location={currentLocation}>
					{children}
				</Transition>
				<Footer />
			</ThemeProvider>
		</BodyWrapper>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
