import React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

const BurgerButton = styled.div`
	height: 9px;
	width: 24px;
	position: relative;
	display: flex;
	align-items: center;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;

	@media ${breakpoint('tablet')} {
		left: 0;
		right: auto;
	}

	@media ${breakpoint('desktop')} {
		height: 15px;
		width: 42px;
	}

	&::before,
	&::after {
		content: '';
		display: block;
		height: 2px;
		width: 100%;
		transition: all 200ms ${(props) => props.theme.ease};
		background: ${getColor('black')};

		@media ${breakpoint('desktop')} {
			height: 3px;
		}
	}

	&::before {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	&::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	${(props) =>
		props.menuActive &&
		css`
			::before {
				top: 50%;
				transform: translate(-50%, -50%) rotate(45deg);
			}

			::after {
				bottom: 50%;
				transform: translate(-50%, 50%) rotate(-45deg);
			}
		`}

	::before,
	::after {
		background: getColor('black');

		@media ${breakpoint('tablet')} {
			background: ${(props) =>
				props.currentPage === 'home' ? getColor('white') : getColor('black')};
		}
	}

	${(props) =>
		props.scrolled &&
		css`
			::before,
			::after {
				background: ${getColor('black')};
			}
		`}
`

export const Burger = ({ currentPage, scrolled, menuOpen, handleClick }) => {
	return (
		<BurgerButton
			onClick={handleClick}
			currentPage={currentPage}
			scrolled={scrolled}
			data-title={menuOpen}
		/>
	)
}
