import React, { useState } from 'react'
import styled from 'styled-components'
import { CreateLocalLink } from '../../utils'
import UniversalLink from '../UniversalLink'
import ArrowIcon from '../../images/arrow-right-orange.svg'

const LinkWrapper = styled.div`
	display: flex;
	margin-bottom: 0;

	@media ${(props) => props.theme.mediaBreakpoints.tablet} {
		margin-bottom: 20px;
	}
`

const SubmenuTrigger = styled.button`
	color: ${(props) => props.theme.colours.white};
	font-size: 25px;
	line-height: 1.7;
	margin-right: 80px;

	@media ${(props) => props.theme.mediaBreakpoints.laptop} {
		font-size: 45px;
	}

	&:hover {
		color: ${(props) => props.theme.colours.orange};
	}

	transition: color ${(props) => props.theme.transitionSpeed.default};
`

const Link = styled(UniversalLink)`
	color: ${(props) => props.theme.colours.white};
	font-size: 25px;
	margin-right: 80px;

	@media ${(props) => props.theme.mediaBreakpoints.laptop} {
		font-size: 45px;
	}

	&:hover {
		color: ${(props) => props.theme.colours.orange};
	}

	transition: color ${(props) => props.theme.transitionSpeed.default};
`

const Arrow = styled.img`
	width: 13px;
	opacity: ${(props) => (props.isLinkHovered ? '1' : '0')};
	transform: ${(props) =>
		props.isLinkHovered ? 'translateX(8px)' : 'translateX(0)'};

	transition: all ${(props) => props.theme.transitionSpeed.default};
`

const MenuItem = ({
	menuItem,
	wordPressUrl,
	parent,
	activeSubmenu,
	subId,
	submenuOpen,
}) => {
	const [currentSubmenu, setCurrentSubmenu] = useState(null)
	const [isLinkHovered, setIsLinkHovered] = useState(false)

	const handleMouseEnter = () => {
		setIsLinkHovered(true)
	}

	const handleMouseLeave = () => {
		setIsLinkHovered(false)
	}

	if (menuItem.parentId != null)
	{
		return null
	}

	return (
		<LinkWrapper>
			{parent && (
				<>
					<SubmenuTrigger
						onClick={() => {
							activeSubmenu(subId)
							setCurrentSubmenu(subId)
							submenuOpen(true)
						}}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						{menuItem.label}
					</SubmenuTrigger>
					<Arrow src={ArrowIcon} isLinkHovered={isLinkHovered} />
				</>
			)}
			{!parent && (
				<Link
					to={CreateLocalLink(menuItem, wordPressUrl)}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					{menuItem.label}
				</Link>
			)}
		</LinkWrapper>
	)
}

export default MenuItem
