import PropTypes from 'prop-types'
import React, { useState, useLayoutEffect, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { HeaderLogo } from '../../components/headerLogo'
import { Burger } from '../../components/burger'
import { breakpoint, getColor } from '../../shared/theme'
import InnerWrapper from '../../elements/innerWrapper'
import Menu from '../../components/menu'

const Nav = styled.nav`
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 50;
	transition: all 200ms ${(props) => props.theme.ease};
	background: ${getColor('white')};

	@media ${breakpoint('tablet')} {
		background: transparent;
	}

	${(props) =>
		props.scrolled &&
		css`
			background: ${getColor('white')};

			@media ${breakpoint('tablet')} {
				background: ${getColor('white')};
			}
		`}

	${(props) =>
		props.menuHidden &&
		css`
			transform: translateY(-100%);
		`}

    ${(props) =>
		props.currentPage === 'home' &&
		css`
			background: ${getColor('white')};
		`}
`

const NavInner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 16px 0;

	@media ${(props) => props.theme.mediaBreakpoints.tablet} {
		padding: 24px 0;
	}

	@media ${(props) => props.theme.mediaBreakpoints.desktop} {
		padding: 36px 0;
	}
`

export const Header = ({ siteTitle, routeSlug, currentLocation }) => {
	const [scrolled, toggleScroled] = useState(false)
	const [visible, toggleHeader] = useState(false)
	const [lastScroll, updateLastScroll] = useState(0)
	const [menuOpen, setMenuOpen] = useState(false)

	const handleClick = () => {
		setMenuOpen(!menuOpen)
	}

	useEffect(() => {
		if (menuOpen) {
			document.body.style.overflowY = 'hidden'
		} else {
			document.body.style.overflowY = 'scroll'
		}
	}, [menuOpen])

	useEffect(() => {
		setMenuOpen(false)
	}, [routeSlug])

	useLayoutEffect(() => {
		function detectScroll() {
			let currentScroll = window.pageYOffset
			if (currentScroll > 0) {
				toggleScroled(true)
			} else {
				toggleScroled(false)
				toggleHeader(true)
			}

			if (scrolled && currentScroll > window.innerHeight / 2) {
				toggleHeader(false)

				if (scrolled && currentScroll < lastScroll) {
					toggleHeader(true)
				}
			}

			updateLastScroll(currentScroll)
		}

		window.addEventListener('scroll', detectScroll)

		return () => {
			window.removeEventListener('scroll', detectScroll)
		}
	}, [lastScroll, scrolled])

	return (
		<>
			<Nav
				currentPage={routeSlug}
				id="header"
				scrolled={scrolled}
				menuHidden={!visible && scrolled}
			>
				<InnerWrapper>
					<NavInner>
						<Burger
							handleClick={handleClick}
							currentPage={routeSlug}
							scrolled={scrolled}
							menuOpen={menuOpen}
						/>

						<HeaderLogo
							currentPage={routeSlug}
							scrolled={scrolled}
							siteTitle={siteTitle}
							currentPage={routeSlug}
						/>
					</NavInner>
				</InnerWrapper>
			</Nav>
			<Menu
				menuOpen={menuOpen}
				handleClick={handleClick}
				currentPage={routeSlug}
				currentLocation={currentLocation}
			/>
		</>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string,
	routeSlug: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
	routeSlug: ``,
}
