// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-index-js": () => import("./../../../src/templates/about/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-confirmation-index-js": () => import("./../../../src/templates/confirmation/index.js" /* webpackChunkName: "component---src-templates-confirmation-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-content-index-js": () => import("./../../../src/templates/content/index.js" /* webpackChunkName: "component---src-templates-content-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-post-blog-js": () => import("./../../../src/templates/post/blog.js" /* webpackChunkName: "component---src-templates-post-blog-js" */),
  "component---src-templates-process-index-js": () => import("./../../../src/templates/process/index.js" /* webpackChunkName: "component---src-templates-process-index-js" */),
  "component---src-templates-work-index-js": () => import("./../../../src/templates/work/index.js" /* webpackChunkName: "component---src-templates-work-index-js" */),
  "component---template-cache-tmp-beauty-js": () => import("./../../../.template-cache/tmp-beauty.js" /* webpackChunkName: "component---template-cache-tmp-beauty-js" */),
  "component---template-cache-tmp-careers-js": () => import("./../../../.template-cache/tmp-careers.js" /* webpackChunkName: "component---template-cache-tmp-careers-js" */),
  "component---template-cache-tmp-ethical-manufacturing-with-slyletica-js": () => import("./../../../.template-cache/tmp-ethical-manufacturing-with-slyletica.js" /* webpackChunkName: "component---template-cache-tmp-ethical-manufacturing-with-slyletica-js" */),
  "component---template-cache-tmp-fashion-without-labels-js": () => import("./../../../.template-cache/tmp-fashion-without-labels.js" /* webpackChunkName: "component---template-cache-tmp-fashion-without-labels-js" */),
  "component---template-cache-tmp-launch-your-own-activewear-brand-in-5-steps-js": () => import("./../../../.template-cache/tmp-launch-your-own-activewear-brand-in-5-steps.js" /* webpackChunkName: "component---template-cache-tmp-launch-your-own-activewear-brand-in-5-steps-js" */),
  "component---template-cache-tmp-our-factories-js": () => import("./../../../.template-cache/tmp-our-factories.js" /* webpackChunkName: "component---template-cache-tmp-our-factories-js" */),
  "component---template-cache-tmp-partnerships-js": () => import("./../../../.template-cache/tmp-partnerships.js" /* webpackChunkName: "component---template-cache-tmp-partnerships-js" */),
  "component---template-cache-tmp-press-js": () => import("./../../../.template-cache/tmp-press.js" /* webpackChunkName: "component---template-cache-tmp-press-js" */),
  "component---template-cache-tmp-slyletica-thrives-js": () => import("./../../../.template-cache/tmp-slyletica-thrives.js" /* webpackChunkName: "component---template-cache-tmp-slyletica-thrives-js" */),
  "component---template-cache-tmp-sustainability-eco-fabrics-js": () => import("./../../../.template-cache/tmp-sustainability-eco-fabrics.js" /* webpackChunkName: "component---template-cache-tmp-sustainability-eco-fabrics-js" */),
  "component---template-cache-tmp-sustainability-js": () => import("./../../../.template-cache/tmp-sustainability.js" /* webpackChunkName: "component---template-cache-tmp-sustainability-js" */),
  "component---template-cache-tmp-the-new-era-of-influencer-marketing-js": () => import("./../../../.template-cache/tmp-the-new-era-of-influencer-marketing.js" /* webpackChunkName: "component---template-cache-tmp-the-new-era-of-influencer-marketing-js" */),
  "component---template-cache-tmp-trends-for-2021-js": () => import("./../../../.template-cache/tmp-trends-for-2021.js" /* webpackChunkName: "component---template-cache-tmp-trends-for-2021-js" */)
}

